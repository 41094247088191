import { Injectable, Signal, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { AssetGroup } from '@iot-platform/models/i4b';
import { AssetGroupsActions } from '../actions';
import { assetGroupsFeature } from '../reducers/asset-groups.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupsFacade {
  private readonly store: Store = inject(Store);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  selectedAssetGroup: Signal<AssetGroup | null> = this.store.selectSignal(assetGroupsFeature.selectSelectedAssetGroup);

  canUpdate: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.UPDATE);
  });

  canDelete: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.DELETE);
  });

  getAssetGroup(assetGroupId: string): void {
    this.store.dispatch(AssetGroupsActions.getAssetGroup({ assetGroupId }));
  }

  addAssetGroup(assetGroup: AssetGroup): void {
    this.store.dispatch(AssetGroupsActions.addAssetGroup({ assetGroup }));
  }
}
