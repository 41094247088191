<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.MANAGE_CONCEPTS.TITLE' | translate: { org: this.data.organization.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20 concept-container">
    <form [formGroup]="conceptsForm" class="" fxLayout="column">
      <iot4bos-ui-backoffice-organization-concepts-selection
        (updateConcepts)="onChangeConcepts($event)"
        [adminConceptsFromEntitySession]="data.adminConceptsFromEntitySession"
        [organization]="data.organization"
        [readonly]="false"
        fxFlex="60"
      ></iot4bos-ui-backoffice-organization-concepts-selection>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ADMIN.DIALOG.ADD_ROLE.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!conceptsForm.dirty" class="button-regular" color="accent" mat-raised-button>
      {{ 'ADMIN.DIALOG.ADD_ROLE.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
