import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { FilterEngineModule } from '@iot-platform/iot-platform-ui';
import { provideConfigureProductsFormSettings, provideVariableCreateFormSettings } from '@iot-platform/iot4bos/ui/asset';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AssetEventsByAssetLogsEffects } from './containers/+state/effects/asset-events-by-asset-logs.effects';
import { AssetEventsByAssetEffects } from './containers/+state/effects/asset-events-by-asset.effects';
import { AssetEventsBySiteLogsEffects } from './containers/+state/effects/asset-events-by-site-logs.effects';
import { AssetEventsBySiteEffects } from './containers/+state/effects/asset-events-by-site.effects';
import { DeviceEventsByDeviceLogsEffects } from './containers/+state/effects/device-events-by-device-logs.effects';
import { DeviceEventsByDeviceEffects } from './containers/+state/effects/device-events-by-device.effects';
import { DeviceEventsBySiteLogsEffects } from './containers/+state/effects/device-events-by-site-logs.effects';
import { DeviceEventsBySiteEffects } from './containers/+state/effects/device-events-by-site.effects';
import { NavigationEffects } from './containers/+state/effects/navigation.effects';
import { NavigationApi } from './containers/+state/navigation.api';
import * as fromNavigation from './containers/+state/reducers';
import { AppShellModule } from './containers/app-shell/app-shell.module';
import { AcknowledgementsComponent } from './features/acknowledgements/acknowledgements.component';
import { ConfigureProductsFormService } from './features/assets/services/configure-products-form.service';
import { VariableCreateFormService } from './features/assets/services/variable-create-form.service';
import { SchedulersEffects } from './features/schedulers/+state/effects/schedulers.effects';
import { SitesModule } from './features/sites/sites.module';
import { FavoriteFilterEngineService } from './services/favorite-filter-engine.service';
import { GridAssetVariableConfigureReadPopupService } from './services/grid-asset-variable-configure-read-popup.service';

const iot4bosRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/app-shell/app-shell.module').then((m) => m.AppShellModule),
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    AppShellModule,
    SitesModule,
    RouterModule.forChild(iot4bosRoutes),
    StoreModule.forFeature(fromNavigation.navigationFeatureKey, fromNavigation.reducers),
    EffectsModule.forFeature([
      NavigationEffects,
      AssetEventsByAssetEffects,
      AssetEventsByAssetLogsEffects,
      AssetEventsBySiteEffects,
      AssetEventsBySiteLogsEffects,
      DeviceEventsByDeviceEffects,
      DeviceEventsByDeviceLogsEffects,
      DeviceEventsBySiteEffects,
      DeviceEventsBySiteLogsEffects,
      SchedulersEffects
    ]),
    ScrollingModule,
    NumberFormatPipe,
    FilterEngineModule.withSettings({
      favoriteFilterEngineService: FavoriteFilterEngineService
    }),
    GridEngineModule.withSettings({
      assetVariableConfigureReadPopupService: GridAssetVariableConfigureReadPopupService
    }),
    AcknowledgementsComponent
  ],
  exports: [RouterModule],
  providers: [
    TranslateService,
    NavigationApi,
    provideVariableCreateFormSettings({
      variableCreateFormService: VariableCreateFormService
    }),
    provideConfigureProductsFormSettings({
      configureProductsFormService: ConfigureProductsFormService
    })
  ],
  declarations: []
})
export class Iot4bosUiModule {}
