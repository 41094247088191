<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.ADD_ROLE.TITLE_EDIT' | translate: { roleName: data.role?.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20 concept-container">
    <form [formGroup]="roleForm" class="" fxLayout="column">
      <iot4bos-ui-backoffice-roles-crud
        (changeAuthorizations)="onAuthorizationsChanged($event)"
        [adminConceptsFromEntitySession]="data.adminConceptsFromEntitySession"
        [isAdmin]="data.isAdmin"
        [readonly]="false"
        [role]="data.role"
        fxFlex="60"
      >
      </iot4bos-ui-backoffice-roles-crud>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!roleForm.dirty" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
