import { NgModule } from '@angular/core';

import { RolesCrudCheckboxComponent } from './roles-crud-checkbox/roles-crud-checkbox.component';
import { RolesCrudComponent } from './roles-crud.component';

@NgModule({
  exports: [RolesCrudComponent, RolesCrudCheckboxComponent],
  imports: [RolesCrudComponent, RolesCrudCheckboxComponent]
})
export class RolesCrudModule {}
