import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DetailPopupCardsModule, UiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { AssetEventsService } from '@iot-platform/shared/services';
import { TableEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommentsPanelComponent } from '../../components/comments/comments-panel/comments-panel.component';
import { EventDetailPopupModule } from '../../components/event-detail-popup/event-detail-popup.module';
import { AssetEventsApi } from './+state/asset-events.api';
import { AssetEventsCommentsEffects } from './+state/effects/asset-events-comments.effects';
import { AssetEventsEffects } from './+state/effects/asset-events.effects';
import * as fromAssetEvents from './+state/reducers';
import { AssetEventsShellComponent } from './containers/asset-events-shell/asset-events-shell.component';

const assetEventsRoutes: Routes = [{ path: '', component: AssetEventsShellComponent }];

@NgModule({
  declarations: [AssetEventsShellComponent],
  exports: [RouterModule],
  imports: [
    SharedModule,
    UiModule,
    DetailPopupCardsModule,
    StoreModule.forFeature(fromAssetEvents.assetEventsFeatureKey, fromAssetEvents.reducers),
    EffectsModule.forFeature([AssetEventsEffects, AssetEventsCommentsEffects]),
    RouterModule.forChild(assetEventsRoutes),
    FavoriteViewsModule,
    TableEngineModule,
    EventDetailPopupModule,
    GridEngineModule,
    CommentsPanelComponent
  ],
  providers: [AssetEventsService, AssetEventsApi, DateFormatPipe, UserNamePipe]
})
export class AssetEventsModule {}
