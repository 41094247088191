import { AsyncPipe } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NameValidators } from '@iot-platform/iot-platform-utils';

import { Organization } from '@iot-platform/models/common';

import { EntitiesService } from '@iot-platform/shared';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCardModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    AsyncPipe,
    MatIcon
  ],
  selector: 'iot4bos-ui-backoffice-edit-organization-dialog',
  templateUrl: './edit-organization-dialog.component.html',
  styleUrls: ['./edit-organization-dialog.component.scss']
})
export class EditOrganizationDialogComponent implements OnInit {
  public data: {
    organization: Organization;
    parentOrganizationId: string;
    parentOrganizationName: string;
  } = inject(MAT_DIALOG_DATA);
  organizationForm: UntypedFormGroup;
  initialName = '';
  private dialogRef: MatDialogRef<EditOrganizationDialogComponent> = inject(MatDialogRef<EditOrganizationDialogComponent>);
  private translateService: TranslateService = inject(TranslateService);
  private entitiesService: EntitiesService = Inject(EntitiesService);

  get nameControl(): AbstractControl {
    return this.organizationForm.get('name');
  }

  get title$(): Observable<string> {
    return this.data.organization
      ? this.translateService.get('ADMIN.DIALOG.ADD_ORGANIZATION.TITLE_EDIT')
      : this.data.parentOrganizationName
        ? this.translateService.get('ADMIN.DIALOG.ADD_ORGANIZATION.TITLE_ADD', { parentOrganizationName: this.data.parentOrganizationName })
        : of('');
  }

  ngOnInit() {
    this.organizationForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.organization ? this.data.organization.name : '', {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: [NameValidators.asyncUniqueNameValidator(this.entitiesService, this.data.organization?.name ?? '')]
      })
    });

    if (this.data.organization) {
      this.initialName = this.data.organization.name;
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let organization: Organization = {
      ...this.data.organization,
      name: this.nameControl.value.trim()
    };

    if (this.data.parentOrganizationId) {
      organization = { ...organization, parentId: this.data.parentOrganizationId };
    }
    this.dialogRef.close(organization);
  }
}
