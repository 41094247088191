import { Component, DestroyRef, inject, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AccountValidationService } from './services/account-validation.service';

@Component({
  standalone: true,
  imports: [FlexLayoutModule],
  selector: 'iot4bos-ui-backoffice-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.scss']
})
export class AccountValidationComponent {
  private accountValidationService: AccountValidationService = inject(AccountValidationService);
  private destroy: DestroyRef = inject(DestroyRef);

  error: Signal<any>;

  constructor() {
    this.error = toSignal(this.accountValidationService.validateSharedAccount().pipe(takeUntilDestroyed(this.destroy)));
  }
}
