// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { Environment } from '@iot-platform/models/common';

export const commonEnvironment: Environment = {
  production: false,
  sso: {
    subdomain: 'iot4bos-dev',
    domain: 'https://iot4bos-dev.auth.eu-west-1.amazoncognito.com',
    clientId: '4d8bpmnelnf2mkljakfakrvpub',
    idpIdentifier: 'okta',
    responseType: 'code',
    redirectUri: 'login/callback'
  },
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_3kPklo82Y',
    clientId: '4d8bpmnelnf2mkljakfakrvpub'
  },
  api: {
    url: '/api/v1',
    url_v2: '/api/v2',
    key: '',
    endpoints: {
      ack: '/ack',
      assetEvents: '/asset-events',
      assetEventsBulkUpdateByStatus: '/operations/assets-events/bulk-',
      assetStatus: '/assets/status', // conf
      assetTemplates: '/asset-templates',
      assetVariables: '/asset-variables',
      assetVariablesBreakFormula: '/deactivate-formula',
      assetVariablesCalculator: '/asset-variables/calculator',
      assetVariablesOperation: '/operations/assets-variables',
      assetVariablesUnits: '/asset-variables/unit_json',
      assets: '/assets',
      assetGroups: '/asset-groups',
      assetsBulkOperationOnTagAdd: '/operations/assets/bulk-add-tag',
      assetsBulkOperationOnTagRemove: '/operations/assets/bulk-remove-tag',
      assetsBulkOperationOnTagReplace: '/operations/assets/bulk-replace-tag',
      assetsBulkSendCommands: '/operations/assets/bulk-send-commands',
      auditTrail: '/audit-trail/trails',
      businessProfiles: '/business-profiles',
      calendars: '/calendars',
      dataGuardChecks: '/checks',
      connector: '/connector',
      connectors: '/connectors',
      connectorConnectors: '/connectors', // TODO : TO BE DELETED WHEN DEVICES MV IS FINISHED
      contacts: '/contacts',
      daysOff: '/days-off',
      deleteAssetsVariables: '/operations/assets-variables/bulk-delete',
      deleteDevicesVariables: '/operations/devices-variables/bulk-delete',
      deviceEvents: '/device-events',
      deviceEventsBulkUpdateByStatus: '/operations/devices-events/bulk-',
      connectorFamilies: '/directory/connector-families',
      deviceStatus: '/devices/status', // conf
      deviceTimeseries: '/device-timeseries',
      deviceVariables: '/device-variables',
      devices: '/devices',
      devicesBulkOperationOnTagAdd: '/operations/devices/bulk-add-tag',
      devicesBulkOperationOnTagRemove: '/operations/devices/bulk-remove-tag',
      devicesBulkOperationOnTagReplace: '/operations/devices/bulk-replace-tag',
      devicesBulkSendCommands: '/operations/devices/bulk-send-commands',
      entities: '/entities',
      erpProxySAP: '/erp-proxy/sap',
      escalationProtocols: '/escalation-protocols',
      exportSpreadsheets: '/export-spreadsheets',
      favoriteViews: '/favorite-views',
      grid: '/grid',
      grids: '/grids',
      importDaysOff: '/import-days-off',
      levels: '/levels',
      logs: '/logs',
      messages: '/messages',
      notificationTemplates: '/notification-templates',
      operatorInfo: '/operator-info',
      overrides: '/overrides',
      poEventAlgos: '/po-event/algos',
      poEventRules: '/po-event/rules',
      productCatalogs: '/catalogs',
      products: '/products',
      reports: '/reports',
      resetAssetVariablesLastValues: '/operations/assets-variables/bulk-reset-last-values',
      resetDeviceVariablesLastValues: '/operations/devices-variables/bulk-reset-last-values',
      rotations: '/rotations',
      schedulers: '/schedulers',
      siteTypes: '/sites/families?lang=', // conf
      sites: '/sites',
      sitesBulkOperationOnTagAdd: '/operations/sites/bulk-add-tag',
      sitesBulkOperationOnTagRemove: '/operations/sites/bulk-remove-tag',
      sitesBulkOperationOnTagReplace: '/operations/sites/bulk-replace-tag',
      tags: '/tag-categories',
      teamPlannings: '/team-plannings',
      thresholds: '/thresholds',
      topics: '/topics',
      topicsBulkSubscribe: '/operations/topics/bulk-subscribe',
      topicsBulkUnsubscribe: '/operations/topics/bulk-unsubscribe',
      users: '/users',
      usersLightWeight: '/directory/users',
      variables: '/variables',
      widgets: '/widgets',
      worldMap: '/worldmap'
    }
  },
  storage: {
    appPrefix: 'iot4bos'
  },
  apiKeys: {
    agGrid:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050013}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ALIZENT_INTERNATIONAL}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Iot4Bos}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Iot4Bos}_need_to_be_licensed___{Iot4Bos}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_January_2025}____[v3]_[01]_MTczNjY0MDAwMDAwMA==146e14b0942e853d657817a9e7490fc0'
  },
  externalEntityManager: false,
  synapseApiCatalog: 'https://onboarding.synapse.airliquide.com/product-detail/al-global/IOT4BOS'
};
