import { PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, Device, DeviceEvent, DeviceVariable, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadDeviceEventsSuccess = createAction('[DeviceEvents] Load DeviceEvents Success', props<{ response: PlatformResponse }>());
export const loadDeviceEventsFailure = createAction('[DeviceEvents] Load DeviceEvents Failure', props<{ error: any }>());
//
export const loadSiteByIdSuccess = createAction('[DeviceEvents] Load Site By Id Success', props<{ site: Site }>());
export const loadSiteByIdFailure = createAction('[DeviceEvents] Load Site By Id Failure', props<{ error: any }>());
//
export const saveTableStateSuccess = createAction('[DeviceEvents] Save Table State Success', props<{ selectedId: string; checkedIds: string[] }>());
export const saveTableStateFailure = createAction('[DeviceEvents]  Save Table State Failure', props<{ error: any }>());
//
export const loadDeviceByIdSuccess = createAction('[DeviceEvents] Load Device By Id Success', props<{ device: Device }>());
export const loadDeviceByIdFailure = createAction('[DeviceEvents] Load Device By Id Failure', props<{ error: any }>());
//
export const loadDeviceVariableByIdSuccess = createAction('[DeviceEvents] Load Device Variable By Id Success', props<{ deviceVariable: DeviceVariable }>());
export const loadDeviceVariableByIdFailure = createAction('[DeviceEvents] Load Device Variable By Id Failure', props<{ error: any }>());
//
export const loadAssetByIdSuccess = createAction('[DeviceEvents] Load Asset By Id Success', props<{ asset: Asset }>());
export const loadAssetByIdFailure = createAction('[DeviceEvents] Load Asset By Id Failure', props<{ error: any }>());
//
export const loadAssetVariableByIdSuccess = createAction('[DeviceEvents] Load Asset Variable By Id Success', props<{ assetVariable: AssetVariable }>());
export const loadAssetVariableByIdFailure = createAction('[DeviceEvents] Load Asset Variable By Id Failure', props<{ error: any }>());
//
export const loadTagsByDeviceEventIdSuccess = createAction('[DeviceEvents] Load Tags By DeviceEvent Id Success', props<{ tags: TagCategory[] }>());
export const loadTagsByDeviceEventIdFailure = createAction('[DeviceEvents] Load Tags By DeviceEvent Id Failure', props<{ error: any }>());
//
export const updateStatusByDeviceEventIdSuccess = createAction('[DeviceEvents] Update Status By DeviceEvent Id Success', props<{ deviceEvent: DeviceEvent }>());
export const updateStatusByDeviceEventIdFailure = createAction('[DeviceEvents] Update Status By DeviceEvent Id Failure', props<{ error: any }>());
//
export const loadMVSettingsSuccess = createAction('[DeviceEvents] Load MV DeviceEvents Settings Success', props<{ settings: any }>());
export const loadMVSettingsFailure = createAction('[DeviceEvents] Load MV DeviceEvents Settings Failure', props<{ error: any }>());
//
export const saveMVSettingsSuccess = createAction('[DeviceEvents] Save MV DeviceEvents Settings Success', props<{ settings: any }>());
export const saveMVSettingsFailure = createAction('[DeviceEvents] Save MV DeviceEvents Settings Failure', props<{ error: any }>());
//
export const bulkUpdateStatusByDeviceEventIdsSuccess = createAction(
  '[DeviceEvents] Bulk Update Status By Device Event Ids - ',
  props<{ response: I4BBulkOperationApiResponse }>()
);
export const bulkUpdateStatusByDeviceEventIdsFailure = createAction(
  '[DeviceEvents] Bulk Update Status By Device Event Ids Failure',
  props<{
    error: any;
  }>()
);
