import { PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetEvent, AssetVariable, Device, DeviceVariable, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEventsSuccess = createAction(
  '[AssetEvents] Load AssetEvents Success',
  props<{
    response: PlatformResponse;
  }>()
);
export const loadAssetEventsFailure = createAction('[AssetEvents] Load AssetEvents Failure', props<{ error: any }>());
//
export const loadSiteByIdSuccess = createAction('[AssetEvents] Load Site By Id Success', props<{ site: Site }>());
export const loadSiteByIdFailure = createAction('[AssetEvents] Load Site By Id Failure', props<{ error: any }>());
//
export const saveTableStateSuccess = createAction(
  '[AssetEvents] Save Table State Success',
  props<{
    selectedId: string;
    checkedIds: string[];
  }>()
);
export const saveTableStateFailure = createAction('[AssetEvents]  Save Table State Failure', props<{ error: any }>());
//
export const loadDeviceByIdSuccess = createAction(
  '[AssetEvents] Load Device By Id Success',
  props<{
    device: Device;
  }>()
);
export const loadDeviceByIdFailure = createAction('[AssetEvents] Load Device By Id Failure', props<{ error: any }>());
//
export const loadDeviceVariableByIdSuccess = createAction(
  '[AssetEvents] Load Device Variable By Id Success',
  props<{
    deviceVariable: DeviceVariable;
  }>()
);
export const loadDeviceVariableByIdFailure = createAction(
  '[AssetEvents] Load Device variable By Id Failure',
  props<{
    error: any;
  }>()
);
//
export const loadAssetByIdSuccess = createAction('[AssetEvents] Load Asset By Id Success', props<{ asset: Asset }>());
export const loadAssetByIdFailure = createAction('[AssetEvents] Load Asset By Id Failure', props<{ error: any }>());
//
export const loadAssetVariableByIdSuccess = createAction(
  '[AssetEvents] Load Asset Variable By Id Success',
  props<{
    assetVariable: AssetVariable;
  }>()
);
export const loadAssetVariableByIdFailure = createAction(
  '[AssetEvents] Load Asset Variable By Id Failure',
  props<{
    error: any;
  }>()
);
//
export const loadTagsByAssetEventIdSuccess = createAction(
  '[AssetEvents] Load Tags By AssetEvent Id Success',
  props<{
    tags: TagCategory[];
  }>()
);
export const loadTagsByAssetEventIdFailure = createAction(
  '[AssetEvents] Load Tags By AssetEvent Id Failure',
  props<{
    error: any;
  }>()
);
//
export const updateStatusByAssetEventIdSuccess = createAction(
  '[AssetEvents] Update Status By AssetEvent Id Success',
  props<{
    assetEvent: AssetEvent;
  }>()
);
export const updateStatusByAssetEventIdFailure = createAction(
  '[AssetEvents] Update Status By AssetEvent Id Failure',
  props<{
    error: any;
  }>()
);
//
export const bulkUpdateStatusByAssetEventIdsSuccess = createAction(
  '[AssetEvents] Bulk Update Status By AssetEvent Ids - ',
  props<{ response: I4BBulkOperationApiResponse }>()
);
export const bulkUpdateStatusByAssetEventIdsFailure = createAction(
  '[AssetEvents] Bulk Update Status By AssetEvent Ids Failure',
  props<{
    error: any;
  }>()
);
//
export const bulkUpdateStatusByAssetEventIdsFromEventListPopupSuccess = createAction(
  '[AssetEvents] Bulk Update Status By AssetEvent Ids From Event List Popup - ',
  props<{ response: I4BBulkOperationApiResponse }>()
);
export const bulkUpdateStatusByAssetEventIdsFromEventListPopupFailure = createAction(
  '[AssetEvents] Bulk Update Status By AssetEvent Ids From Event List Popup Failure',
  props<{ error: any }>()
);

export const loadMVSettingsSuccess = createAction(
  '[AssetEvents] Load MV AssetEvents Settings Success',
  props<{
    settings: any;
  }>()
);
export const loadMVSettingsFailure = createAction(
  '[AssetEvents] Load MV AssetEvents Settings Failure',
  props<{
    error: any;
  }>()
);
//
export const saveMVSettingsSuccess = createAction(
  '[AssetEvents] Save MV AssetEvents Settings Success',
  props<{
    settings: any;
  }>()
);
export const saveMVSettingsFailure = createAction(
  '[AssetEvents] Save MV AssetEvents Settings Failure',
  props<{
    error: any;
  }>()
);
