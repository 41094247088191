<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.ADD_USER.TITLE' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content p-20" fxLayout="column">
    <form [formGroup]="usersForm" fxFlex fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <mat-label class="capitalize-first-letter" id="is-shared-radio-group-label">{{ 'ADMIN.USERS.TABLE.ACCOUNT_TYPE' | translate }} </mat-label>
        <mat-radio-group
          [value]="isShared"
          aria-labelledby="is-shared-radio-group-label"
          color="accent"
          formControlName="isShared"
          fxLayout="row"
          fxLayoutGap="30px"
        >
          <mat-radio-button [value]="false">{{ 'ADMIN.USERS.TABLE.TYPE.USER_ACCOUNT' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true">{{ 'ADMIN.USERS.TABLE.TYPE.SHARED_ACCOUNT' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-hint class="account-type-disclaimer">
        {{
          (data.externalEntityManager && !isShared.value
            ? 'ADMIN.DIALOG.ADD_USER.CARM_DISCLAIMER'
            : isShared.value
              ? 'ADMIN.DIALOG.ADD_USER.SHARED_ACCOUNT_DISCLAIMER'
              : 'ADMIN.DIALOG.ADD_USER.USER_ACCOUNT_DISCLAIMER'
          ) | translate
        }}
      </mat-hint>
      @if (data.externalEntityManager && !isShared.value && !!carmURL.length) {
        <a [href]="carmURL" target="_blank">{{ carmURL }}</a>
      }
      @if (!data.externalEntityManager || isShared.value) {
        <div>
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            @if (!isShared.value) {
              <mat-form-field color="accent" fxFlex>
                <mat-label>{{ 'ADMIN.DIALOG.ADD_USER.FIRST_NAME_PLACEHOLDER' | translate }}</mat-label>
                <input formControlName="firstname" matInput maxlength="50" required />
              </mat-form-field>
            }
            <mat-form-field color="accent" fxFlex>
              <mat-label
                >{{ (isShared.value ? 'ADMIN.DIALOG.ADD_USER.SHARED_ACCOUNT_NAME_PLACEHOLDER' : 'ADMIN.DIALOG.ADD_USER.LAST_NAME_PLACEHOLDER') | translate }}
              </mat-label>
              <input formControlName="lastname" matInput maxlength="50" required />
            </mat-form-field>
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'ADMIN.DIALOG.ADD_USER.EMAIL_PLACEHOLDER' | translate }}</mat-label>
              <input (input)="emailControl.setValue($event.target.value.toLowerCase())" formControlName="email" matInput maxlength="50" required />
              @if (emailControl.invalid && emailControl.errors.used) {
                <mat-error>{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_EMAIL' | translate }}</mat-error>
              }
              @if (emailControl.invalid && emailControl.errors?.email) {
                <mat-error>{{ 'FORM.ERROR_MESSAGE.INVALID_EMAIL' | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      }
      <span>{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</span>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ADMIN.DIALOG.ADD_USER.CANCEL' | translate | uppercase }}</button>
    <button (click)="save()" [disabled]="isUserFormDisabled()" class="button-regular" color="accent" mat-raised-button>
      {{ 'ADMIN.DIALOG.ADD_USER.SAVE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
