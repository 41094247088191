import { UpperCasePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EmailValidators } from '@iot-platform/iot-platform-utils';
import { BaseUser } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UsersService } from '../../../features/users/services/users.service';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCardModule,
    MatIcon,
    ReactiveFormsModule,
    MatInput,
    MatFormFieldModule,
    TranslateModule,
    MatButtonModule,
    MatToolbarModule,
    MatRadioModule,
    UpperCasePipe
  ],
  selector: 'iot4bos-ui-backoffice-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  public data: { externalEntityManager?: boolean } = inject(MAT_DIALOG_DATA);
  usersForm!: UntypedFormGroup;
  protected dialogRef: MatDialogRef<AddUserDialogComponent> = inject(MatDialogRef<AddUserDialogComponent>);
  protected usersService: UsersService = inject(UsersService);
  carmURL = this.usersService.getCarmUrl();

  get firstnameControl(): AbstractControl {
    return this.usersForm.get('firstname') as AbstractControl;
  }

  get lastnameControl(): AbstractControl {
    return this.usersForm.get('lastname') as AbstractControl;
  }

  get emailControl(): AbstractControl {
    return this.usersForm.get('email') as AbstractControl;
  }

  get isShared(): AbstractControl {
    return this.usersForm.get('isShared') as AbstractControl;
  }

  ngOnInit() {
    this.usersForm = new UntypedFormGroup({
      firstname: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      lastname: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new UntypedFormControl('', [Validators.required, EmailValidators.isValid()], [this.checkEmail.bind(this)]),
      isShared: new UntypedFormControl(this.data?.externalEntityManager)
    });
  }

  checkEmail(email: UntypedFormControl): Observable<{ used: boolean } | null | undefined> {
    if (!this.usersForm) {
      return of(null);
    }

    if (this.emailControl.value) {
      return this.usersService.isUserEmailUnique(email.getRawValue() as string).pipe(
        map((unique: boolean) => (unique ? null : { used: true })),
        take(1)
      );
    } else {
      return of(null);
    }
  }

  isUserFormDisabled(): boolean {
    if (this.isShared.value) {
      return !(this.lastnameControl.valid && this.emailControl.valid);
    } else {
      return !this.usersForm.valid;
    }
  }

  save() {
    const newUser: Partial<BaseUser> = {
      firstname: this.isShared.value ? '' : this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      email: this.emailControl.value,
      isShared: this.isShared.value
    };
    this.dialogRef.close(newUser);
  }

  close() {
    this.dialogRef.close();
  }
}
