import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { GridManagerUserPopupComponent, GridsService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  FavoriteViewFormComponent,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  ManageTagsDialogComponent,
  PopupComponent
} from '@iot-platform/iot-platform-ui';
import { BusinessProfile, FavoriteView, IotToolbarEvent, Pagination, PlatformResponse, TagCategory, ToolbarSize } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AddBusinessProfileDialogComponent } from '../../../components/dialogs/add-business-profile-dialog/add-business-profile-dialog.component';
// tslint:disable-next-line:max-line-length
import { ManageBusinessProfileMembersDialogComponent } from '../../../components/dialogs/manage-business-profile-members-dialog/manage-business-profile-members-dialog.component';
// tslint:disable-next-line:max-line-length
import { ManageBusinessProfileRolesDialogComponent } from '../../../components/dialogs/manage-business-profile-roles-dialog/manage-business-profile-roles-dialog.component';
import { AdminOrganizationsBusinessProfilesPageActions } from '../../admin-organizations/state/actions';
import { FavoriteViewsDetailComponent } from '../components/favorite-views-detail/favorite-views-detail.component';
import {
  BusinessProfilesApiActions,
  BusinessProfilesFavoriteViewsPageActions,
  BusinessProfilesGridsPageActions,
  BusinessProfilesPageActions,
  BusinessProfilesTagsPageActions
} from '../state/actions';
import * as fromBusinessProfiles from '../state/reducers';

@Component({
  selector: 'iot4bos-ui-backoffice-admin-business-profiles',
  templateUrl: './admin-business-profiles.component.html',
  styleUrls: ['../../../style/admin.style.scss', './admin-business-profiles.component.scss']
})
export class AdminBusinessProfilesComponent implements OnInit, OnDestroy {
  @Input() displayGridsTab = true;
  @Input() displayFavoriteViewsTab = true;

  analytics: AnalyticsService = new AnalyticsService('admin_business_profiles_shell');
  businessProfilesButtonList!: IotToolbarDefaultButton[];

  total$ = this.store.select(fromBusinessProfiles.getTotalBusinessProfiles);
  filteredProfiles$ = this.store.select(fromBusinessProfiles.getFilteredBusinessProfiles);
  selectedBusinessProfile$ = this.store.select(fromBusinessProfiles.getSelectedBusinessProfile) as Observable<BusinessProfile>;
  searchString$ = this.store.select(fromBusinessProfiles.getSearchString) as Observable<string>;

  members$ = this.store.select(fromBusinessProfiles.getAllMembersByBusinessProfile);
  roles$ = this.store.select(fromBusinessProfiles.getAllRolesByBusinessProfile);
  tags$ = this.store.select(fromBusinessProfiles.getAllTagsWithSortedLabels);
  tagsPendingStatus$ = this.store.select(fromBusinessProfiles.getTagsPendingStatus);
  rolesPendingStatus$ = this.store.select(fromBusinessProfiles.getRolesPendingStatus);
  membersPendingStatus$ = this.store.select(fromBusinessProfiles.getMembersPendingStatus);

  businessProfilesPendingStatus$ = this.store.select(fromBusinessProfiles.getBusinessProfilesPendingStatus);
  sharedFavoriteViewsByBusinessProfile$ = this.store.select(fromBusinessProfiles.selectSharedFavoriteViewsAsPlatformResponse);
  favoriteViewsPendingStatus$ = this.store.select(fromBusinessProfiles.getFavoriteViewsPendingStatus);

  tags: TagCategory[];

  sharedGridsByBusinessProfile$: Observable<PlatformResponse> = this.store.select(fromBusinessProfiles.selectSharedGridsByBusinessProfileAsPlatformResponse);

  canCreateBusinessProfile: boolean;
  canUpdateBusinessProfile: boolean;
  canDeleteBusinessProfile: boolean;
  canReadOrganizations: boolean;

  selectedBusinessProfile: BusinessProfile;

  userPermissions: any;
  masterViews = ['site', 'asset', 'device', 'asset-event', 'device-event'];
  userId?: string;
  destroyed$: Subject<boolean> = new Subject<boolean>();

  toolbarSize: string = ToolbarSize.SMALL;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly authz: AuthorizationService,
    private readonly gridsService: GridsService
  ) {
    this.selectedBusinessProfile$.pipe(takeUntil(this.destroyed$)).subscribe((profile: BusinessProfile) => (this.selectedBusinessProfile = profile));
    this.store
      .select(fromAuth.selectAccount)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user) => (this.userId = user?.id));

    this.tags$.subscribe((cat) => (this.tags = cat));

    this.canCreateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE);
    this.canUpdateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.canDeleteBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.DELETE);
    this.canReadOrganizations = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.READ);
    this.userPermissions = [{ key: 'canUpdateBusinessProfile', value: this.canUpdateBusinessProfile }];

    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.store.dispatch(BusinessProfilesPageActions.listBusinessProfiles());
  }

  initToolbarButtonList(): void {
    this.businessProfilesButtonList = [
      new IotToolbarDefaultButton(
        {
          ...ADD_BUTTON_CONFIG,
          displayButton: this.canCreateBusinessProfile,
          tooltip: 'ADMIN.BUSINESS_PROFILES.TOOLBAR_BUTTON_TOOLTIP'
        },
        0
      )
    ];
  }

  selectBusinessProfile(businessProfile: BusinessProfile) {
    this.store.dispatch(BusinessProfilesApiActions.selectBusinessProfile({ selectedBusinessProfileId: businessProfile.id }));
  }

  navigateToSelectedOrganization(organizationId: string) {
    if (this.canReadOrganizations) {
      this.analytics.log('toolbar_actions', 'navigate_to_organization');
      this.store.dispatch(BusinessProfilesPageActions.navigateToSelectedOrganization({ organizationId }));
    }
  }

  filter(searchString: string) {
    this.analytics.log('filter_actions', 'filter_bp_list');
    this.store.dispatch(BusinessProfilesPageActions.filterBusinessProfiles({ searchString }));
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.onAddBusinessProfile();
        break;
      default:
        break;
    }
  }

  onAddBusinessProfile() {
    this.analytics.log('toolbar_actions', 'open_add_bp');
    this.dialog
      .open(AddBusinessProfileDialogComponent, {
        width: '550px',
        disableClose: true,
        data: {}
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newProfile: BusinessProfile) => {
        if (newProfile) {
          this.analytics.log('toolbar_actions', 'add_bp');
          this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.addBusinessProfileToOrganization({ businessProfileToAdd: newProfile }));
        }
      });
  }

  editBusinessProfile(businessProfile: BusinessProfile) {
    this.analytics.log('toolbar_actions', 'open_edit_bp');
    this.dialog
      .open(AddBusinessProfileDialogComponent, {
        width: '550px',
        disableClose: true,
        data: { businessProfile }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((updated: BusinessProfile) => {
        if (updated) {
          this.analytics.log('toolbar_actions', 'update_bp');
          this.store.dispatch(
            BusinessProfilesPageActions.updateBusinessProfile({
              businessProfile: {
                ...this.selectedBusinessProfile,
                name: updated.name,
                timezoneDetails: updated.timezoneDetails,
                chartPeriod: updated.chartPeriod,
                siteStocksVisible: updated.siteStocksVisible,
                timeseriesDisplayMode: updated.timeseriesDisplayMode
              }
            })
          );
        }
      });
  }

  onUpdateProfile(data: { businessProfileId: string; updatedProfile: BusinessProfile }) {
    this.analytics.log('info_tab_actions', 'update_bp');
    this.store.dispatch(BusinessProfilesPageActions.updateBusinessProfile({ businessProfile: data.updatedProfile }));
  }

  onAddMembersToProfile() {
    this.analytics.log('info_tab_actions', 'open_add_members_to_bp');
    this.dialog.open(ManageBusinessProfileMembersDialogComponent, {
      width: '900px',
      data: { businessProfile: this.selectedBusinessProfile }
    });
  }

  onAddRolesToProfile() {
    this.analytics.log('info_tab_actions', 'open_add_roles_to_bp');
    this.dialog.open(ManageBusinessProfileRolesDialogComponent, {
      width: '900px',
      data: { businessProfile: this.selectedBusinessProfile }
    });
  }

  onAddTagsToProfile(concept: string) {
    this.analytics.log('tag_tab_actions', 'open_manage_bp_tags');
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['site', 'asset', 'device', 'event'],
          openOnConcept: concept,
          selectedTags: this.tags,
          objectName: this.selectedBusinessProfile.name,
          currentEntityId: this.selectedBusinessProfile.entityId,
          multiSelection: true,
          withChildren: true,
          joinable: false,
          withParents: true
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags: TagCategory[]) => {
        if (tags) {
          this.analytics.log('tag_tab_actions', 'manage_bp_tags');
          this.store.dispatch(
            BusinessProfilesTagsPageActions.updateTagsForBusinessProfile({
              businessProfileId: this.selectedBusinessProfile.id,
              tagsToAdd: tags
            })
          );
        }
      });
  }

  getDeleteTooltip(): Observable<string> {
    return combineLatest([this.members$, this.roles$]).pipe(
      map(([members, roles]) =>
        members.length !== 0 && roles.length !== 0
          ? `Business Profile is linked to ${members.length} users and ${roles.length} roles`
          : roles.length !== 0
            ? `Business Profile is linked to ${roles.length} roles`
            : members.length !== 0
              ? `Business Profile is linked to ${members.length} users`
              : 'Delete'
      )
    );
  }

  isDeletePossible(): Observable<boolean> {
    return combineLatest([this.members$, this.roles$]).pipe(map(([members, roles]) => members.length !== 0 || roles.length !== 0));
  }

  deleteBusinessProfile(businessProfile: BusinessProfile) {
    this.analytics.log('toolbar_actions', 'open_delete_bp');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: {
          type: 'delete',
          value: businessProfile.name
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: boolean) => {
        if (value) {
          this.analytics.log('toolbar_actions', 'delete_bp');
          this.store.dispatch(BusinessProfilesPageActions.deleteBusinessProfile({ businessProfile }));
        }
      });
  }

  onFavoriteViewManagerAction(action: { type: string; options: any }) {
    switch (action.type.toLowerCase()) {
      case 'open':
        this.openFavoriteViewDetail(action.options.selected);
        break;
      case 'delete':
        this.deleteFavoriteView(action.options.element);
        break;
      case 'edit':
        this.updateFavoriteView(action.options.element);
        break;
      case 'duplicate':
        this.duplicateFavoriteView(action.options.element);
        break;
      default:
        break;
    }
  }

  deleteFavoriteView(favoriteViewToDelete: FavoriteView): void {
    this.analytics.log('fv_tab_actions', 'open_delete_fv');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: favoriteViewToDelete.name }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((validation: boolean) => {
        if (validation) {
          this.analytics.log('fv_tab_actions', 'delete_fv');
          this.store.dispatch(BusinessProfilesFavoriteViewsPageActions.deleteFavoriteView({ favoriteViewToDelete }));
        }
      });
  }

  openFavoriteViewDetail(favoriteView: FavoriteView): void {
    this.analytics.log('fv_tab_actions', 'open_fv_detail');
    this.dialog.open(FavoriteViewsDetailComponent, {
      minWidth: '600px',
      maxWidth: '800px',
      disableClose: false,
      data: { favoriteView }
    });
  }

  onAddFavoriteView(masterView: string) {
    this.analytics.log('fv_tab_actions', 'open_add_fv');
    const mv = masterView === ('asset-event' || 'device-event') ? 'EVENT' : masterView.toUpperCase();
    const favoriteView: FavoriteView = {
      masterView: masterView + 's',
      concept: mv,
      filters: [],
      businessProfileId: this.selectedBusinessProfile.id
    };
    this.dialog
      .open(FavoriteViewFormComponent, {
        width: '1100px',
        disableClose: true,
        data: {
          favoriteView,
          canUpdateBusinessProfile: this.canUpdateBusinessProfile,
          forcedShare: true,
          adminMode: true,
          userId: this.userId
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: { grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (data) {
          if (data.grid) {
            this.analytics.log('fv_tab_actions', 'add_fv_with_a_grid');
            this.store.dispatch(
              BusinessProfilesFavoriteViewsPageActions.duplicateGridThenAddFavoriteView({
                grid: data.grid,
                favoriteViewToAdd: data.favoriteView
              })
            );
          } else {
            this.analytics.log('fv_tab_actions', 'add_fv');
            this.store.dispatch(BusinessProfilesFavoriteViewsPageActions.addFavoriteView({ favoriteViewToAdd: data.favoriteView }));
          }
        }
      });
  }

  updateFavoriteView(favoriteView: FavoriteView): void {
    this.analytics.log('fv_tab_actions', 'open_edit_fv');
    this.dialog
      .open(FavoriteViewFormComponent, {
        width: '1100px',
        disableClose: true,
        data: {
          favoriteView,
          canUpdateBusinessProfile: this.canUpdateBusinessProfile,
          forcedShare: true,
          userId: this.userId
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: { grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (data) {
          if (data.grid) {
            this.analytics.log('fv_tab_actions', 'update_fv_with_a_grid');
            this.store.dispatch(
              BusinessProfilesFavoriteViewsPageActions.duplicateGridThenUpdateFavoriteView({
                grid: data.grid,
                favoriteViewToUpdate: data.favoriteView
              })
            );
          } else {
            this.analytics.log('fv_tab_actions', 'update_fv');
            this.store.dispatch(BusinessProfilesFavoriteViewsPageActions.updateFavoriteView({ favoriteViewToUpdate: data.favoriteView }));
          }
        }
      });
  }

  duplicateFavoriteView(favoriteView: FavoriteView): void {
    this.analytics.log('fv_tab_actions', 'open_duplicate_fv');
    this.dialog
      .open(FavoriteViewFormComponent, {
        width: '1100px',
        disableClose: true,
        data: {
          favoriteView,
          canUpdateBusinessProfile: this.canUpdateBusinessProfile,
          forcedShare: true,
          duplicateMode: true,
          userId: this.userId
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: { grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (data) {
          if (!data.grid || this.selectedBusinessProfile.id === data.favoriteView.businessProfileId) {
            this.analytics.log('fv_tab_actions', 'duplicate_fv');
            this.store.dispatch(BusinessProfilesFavoriteViewsPageActions.duplicateFavoriteView({ favoriteViewToDuplicate: data.favoriteView }));
          } else {
            this.analytics.log('fv_tab_actions', 'duplicate_fv_and_grid');
            this.store.dispatch(
              BusinessProfilesFavoriteViewsPageActions.duplicateGridAndFavoriteView({
                gridToDuplicate: data.grid,
                favoriteViewToDuplicate: data.favoriteView
              })
            );
          }
        }
      });
  }

  onFVPageChange(pagination: Pagination) {
    this.store.dispatch(BusinessProfilesFavoriteViewsPageActions.changePage({ pagination }));
  }

  onGridPageChange(pagination: Pagination) {
    this.store.dispatch(BusinessProfilesGridsPageActions.changePage({ pagination }));
  }

  onAddGrid(masterView: string): void {
    this.analytics.log('grid_tab_actions', 'open_add_grid');
    this.gridsService
      .getAppDefaultGridByConcept(masterView + 's')
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((appDefaultGrid) =>
          this.dialog
            .open(GridManagerUserPopupComponent, {
              width: '1350px',
              maxWidth: '1350px',
              disableClose: true,
              data: { grid: appDefaultGrid, isAdminMode: true, selectedBusinessProfile: this.selectedBusinessProfile }
            })

            .afterClosed()
        )
      )
      .subscribe((gridToAdd) => {
        if (gridToAdd) {
          this.analytics.log('grid_tab_actions', 'add_grid');
          this.store.dispatch(BusinessProfilesGridsPageActions.addGrid({ gridToAdd }));
        }
      });
  }

  onUpdateGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): void {
    this.analytics.log('grid_tab_actions', 'open_edit_grid');
    this.gridsService
      .loadGridDetails(grid.masterview, grid.id)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((consolidatedGrid) =>
          this.dialog
            .open(GridManagerUserPopupComponent, {
              width: '1350px',
              maxWidth: '1350px',
              disableClose: true,
              data: {
                grid: { ...consolidatedGrid, data: null },
                isAdminMode: true,
                selectedBusinessProfile: this.selectedBusinessProfile
              }
            })
            .afterClosed()
        )
      )
      .subscribe((gridToUpdate) => {
        if (gridToUpdate) {
          this.analytics.log('grid_tab_actions', 'update_grid');
          this.store.dispatch(BusinessProfilesGridsPageActions.updateGrid({ gridToUpdate }));
        }
      });
  }

  onDeleteGrid(gridToDelete: I4BGrid<I4BGridOptions, I4BGridData>): void {
    this.analytics.log('grid_tab_actions', 'open_delete_grid');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: gridToDelete.name }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((validation: boolean) => {
        if (validation) {
          this.analytics.log('grid_tab_actions', 'delete_grid');
          this.store.dispatch(BusinessProfilesGridsPageActions.deleteGrid({ gridToDelete }));
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
