import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { AssetGroup } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupsService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  getAssetGroup(assetGroupId: string): Observable<AssetGroup> {
    return this.httpClient.get<AssetGroup>(`${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}/${assetGroupId}`);
  }

  addAssetGroup(assetGroup: AssetGroup): Observable<AssetGroup> {
    return this.httpClient.post<AssetGroup>(`${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}`, assetGroup);
  }
}
