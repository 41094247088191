<mat-nav-list [disableRipple]="true" class="two-line-list">
  @for (profile of profiles(); track profile.id) {
    <mat-list-item
      (click)="onSelectBusinessProfileButtonClick(profile)"
      [lines]="2"
      [ngClass]="{ selected: selectedBusinessProfile() && profile ? selectedBusinessProfile().id === profile.id : false }"
      class="hoverable"
    >
      <mat-icon [svgIcon]="'business_profile'" matListItemAvatar style="font-size: 24px"></mat-icon>
      <h3 matListItemTitle>{{ profile.name }}</h3>
      @if (canDeleteBusinessProfile() || canUpdateBusinessProfile()) {
        <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionMenu" class="bp-call-to-action" mat-icon-button matListItemMeta>
          <mat-icon>more_vert</mat-icon>
        </button>
      }
      <p class="complementary-line" matListItemLine>
        <span>{{ profile.entityName }}</span>
      </p>
      <mat-menu #actionMenu="matMenu">
        @if (canUpdateBusinessProfile()) {
          <button (click)="selectBusinessProfile.emit(profile)" mat-menu-item>
            <span> {{ 'IOT_DICTIONARY.EDIT' | translate | uppercase }}</span>
          </button>
        }
        @if (canDeleteBusinessProfile()) {
          <div [matTooltipDisabled]="isDeletePossible(profile)" [matTooltip]="getTooltip(profile)" matTooltipClass="regular-tooltip">
            <button (click)="onDeleteBusinessProfileButtonClick(profile)" [disabled]="!isDeletePossible(profile)" mat-menu-item>
              <span> {{ 'IOT_DICTIONARY.DELETE' | translate | uppercase }}</span>
            </button>
          </div>
        }
      </mat-menu>
    </mat-list-item>
  }
</mat-nav-list>
