<div class="organization-list-container full-height">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      [matTreeNodePaddingIndent]="16"
      [ngClass]="{ selected: node && selectedOrganization() ? selectedOrganization().id === node.id : false }"
      matTreeNodePadding
    >
      <!-- use a disabled button to provide padding for tree leaf -->
      <button disabled mat-icon-button></button>
      <div (click)="onSelectOrganization(node)" class="organization-list-node-name" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <span>{{ node.name }}</span>
        @if (node.isLocked) {
          <mat-icon class="organization-list-node-name__lock-icon">lock_outline</mat-icon>
        }
        <span fxFlex></span>
        @if (canUpdateOrganization() && (node.isLocked || isTopLevelAdmin())) {
          <button
            [matTooltip]="(node.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button add-orga-button organization-list-node-name__lock-btn"
            [disabled]="!isTopLevelAdmin()"
            (click)="onLockUnlockOrganization(node, !node?.isLocked)"
          >
            <mat-icon>{{ node.isLocked ? 'lock_open_outline' : 'lock_outline' }}</mat-icon>
          </button>
        }
        @if (canCreateOrganization()) {
          <button
            [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ORGANIZATION' | translate"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button add-orga-button"
            (click)="onAddOrganization(node.id)"
          >
            <mat-icon>add</mat-icon>
          </button>
        }
      </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      [matTreeNodePaddingIndent]="16"
      [ngClass]="{ selected: node && selectedOrganization() ? selectedOrganization().id === node.id : false }"
      matTreeNodePadding
    >
      <button [attr.aria-label]="'toggle ' + node.name" mat-icon-button matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <div (click)="onSelectOrganization(node)" class="organization-list-node-name" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <span>{{ node.name }}</span>
        @if (node.isLocked) {
          <mat-icon class="organization-list-node-name__lock-icon">lock_outline</mat-icon>
        }
        <span fxFlex></span>
        @if (canUpdateOrganization() && (node.isLocked || isTopLevelAdmin())) {
          <button
            [matTooltip]="(node.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button add-orga-button organization-list-node-name__lock-btn"
            [disabled]="!isTopLevelAdmin()"
            (click)="onLockUnlockOrganization(node, !node?.isLocked)"
          >
            <mat-icon>{{ node.isLocked ? 'lock_open_outline' : 'lock_outline' }}</mat-icon>
          </button>
        }
        @if (canCreateOrganization()) {
          <button
            [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ORGANIZATION' | translate"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button add-orga-button"
            (click)="onAddOrganization(node.id)"
          >
            <mat-icon>add</mat-icon>
          </button>
        }
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
