import { inject } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { CommonGenericModel } from '@iot-platform/models/common';
import { DeviceEvent, Log } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { DeviceEventsService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { DeviceEventsCommentsActions } from '../actions';

const loadDeviceEventComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.loadComments),
      concatMap(({ deviceEvent }) =>
        deviceEventsService.loadComments(deviceEvent).pipe(
          map((comments: Log[]) => DeviceEventsCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(DeviceEventsCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.addComment),
      concatMap(({ deviceEvent, comment }) =>
        deviceEventsService.addComment(deviceEvent.id, comment).pipe(
          switchMap((addedComment: Log) => [
            DeviceEventsCommentsActions.addCommentSuccess({ comment: addedComment }),
            GridsDbActions.updateItemInAllGridsData({
              updatedItem: { ...deviceEvent, totalComments: deviceEvent.totalComments + 1 } as CommonGenericModel,
              concept: 'device-events'
            })
          ]),
          catchError((error) => of(DeviceEventsCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.editComment),
      concatMap(({ deviceEventId, comment }) =>
        deviceEventsService.editComment(deviceEventId, comment).pipe(
          map((editedComment: Log) => DeviceEventsCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(DeviceEventsCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

//
const addOrEditCommentSuccessThenRefreshEventInAllGrids$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.addCommentSuccess, DeviceEventsCommentsActions.editCommentSuccess),
      concatMap(({ comment }) =>
        deviceEventsService.getDeviceEventById(comment.eventIdentifier).pipe(
          map((updatedItem: DeviceEvent) =>
            GridsDbActions.updateItemInAllGridsData({
              updatedItem,
              concept: 'device-events'
            })
          ),
          catchError((error) => of(DeviceEventsCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.deleteComment),
      concatMap(({ deviceEvent, commentId }) =>
        deviceEventsService.deleteComment(deviceEvent.id, commentId).pipe(
          switchMap((deletedCommentId: string) => [
            DeviceEventsCommentsActions.deleteCommentSuccess({ commentId: deletedCommentId }),
            GridsDbActions.updateItemInAllGridsData({
              updatedItem: {
                ...deviceEvent,
                totalComments: deviceEvent.totalComments > 0 ? deviceEvent.totalComments - 1 : 0
              } as CommonGenericModel,
              concept: 'device-events'
            })
          ]),
          catchError((error) => of(DeviceEventsCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteCommentSuccessThenRefreshEventInAllGrids$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService), store = inject(Store)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.deleteCommentSuccess),
      withLatestFrom(store.select(fromGrids.getSelectedItemInSelectedGrid)),
      concatMap(([action, event]) =>
        deviceEventsService.getDeviceEventById(event.id).pipe(
          map((updatedItem: DeviceEvent) =>
            GridsDbActions.updateItemInAllGridsData({
              updatedItem,
              concept: 'device-events'
            })
          ),
          catchError((error) => of(DeviceEventsCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsCommentsActions.loadComments,
        DeviceEventsCommentsActions.addComment,
        DeviceEventsCommentsActions.editComment,
        DeviceEventsCommentsActions.deleteComment
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsCommentsActions.loadCommentsSuccess,
        DeviceEventsCommentsActions.loadCommentsFailure,
        DeviceEventsCommentsActions.addCommentSuccess,
        DeviceEventsCommentsActions.addCommentFailure,
        DeviceEventsCommentsActions.editCommentSuccess,
        DeviceEventsCommentsActions.editCommentFailure,
        DeviceEventsCommentsActions.deleteCommentSuccess,
        DeviceEventsCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.addCommentSuccess, DeviceEventsCommentsActions.editCommentSuccess, DeviceEventsCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsCommentsActions.loadCommentsFailure,
        DeviceEventsCommentsActions.addCommentFailure,
        DeviceEventsCommentsActions.editCommentFailure,
        DeviceEventsCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const DeviceEventsCommentsEffects = {
  loadDeviceEventComments$,
  addComment$,
  editComment$,
  deleteComment$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$,
  deleteCommentSuccessThenRefreshEventInAllGrids$,
  addOrEditCommentSuccessThenRefreshEventInAllGrids$
};
