<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  @for (right of CRUD | keyvalue: defaultOrder; track right.key) {
    <mat-checkbox
      color="accent"
      class="role-crud-checkbox"
      fxLayoutAlign="center center"
      [disabled]="disabled()"
      [checked]="innerAuthorizations()[right.key]"
      (change)="onAuthorizationChange(right.key, $event)"
    />
  }
</div>
