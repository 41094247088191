import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopupComponent } from '@iot-platform/iot-platform-ui';

import { BusinessProfile } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, UpperCasePipe, MatIcon, MatMenuModule, MatTooltipModule, MatListModule, NgClass, MatIconButton],
  selector: 'iot4bos-ui-backoffice-business-profiles-list',
  templateUrl: './business-profiles-list.component.html',
  styleUrls: ['./business-profiles-list.component.scss', '../../style/admin.style.scss']
})
export class BusinessProfilesListComponent {
  dialog: MatDialog = inject(MatDialog);

  profiles = input<BusinessProfile[]>([]);
  canUpdateBusinessProfile = input<boolean>(false);
  canDeleteBusinessProfile = input<boolean>(false);
  selectedBusinessProfile = input<BusinessProfile>();
  navigateOnClick = input<boolean>(false);

  selectBusinessProfile = output<BusinessProfile>();
  deleteBusinessProfile = output<BusinessProfile>();

  onDeleteBusinessProfileButtonClick(profile: BusinessProfile): void {
    this.confirmDeletion(profile);
  }

  onSelectBusinessProfileButtonClick(businessProfile: BusinessProfile): void {
    if (this.navigateOnClick()) {
      this.selectBusinessProfile.emit(businessProfile);
    }
  }

  getTooltip(profile: BusinessProfile): string {
    return profile.userTotal !== 0 && profile.roleTotal !== 0
      ? `Business Profile is linked to ${profile.userTotal} users and ${profile.roleTotal} roles`
      : profile.roleTotal !== 0
        ? `Business Profile is linked to ${profile.roleTotal} roles`
        : profile.userTotal !== 0
          ? `Business Profile is linked to ${profile.userTotal} users`
          : '';
  }

  isDeletePossible(profile: BusinessProfile): boolean {
    return profile.roleTotal === 0 && profile.userTotal === 0;
  }

  private confirmDeletion(businessProfileToDelete: BusinessProfile) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: businessProfileToDelete.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteBusinessProfile.emit(businessProfileToDelete);
      }
    });
  }
}
