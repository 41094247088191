import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountValidationService {
  private environment: Environment = inject(ENVIRONMENT);
  private http: HttpClient = inject(HttpClient);
  private route: ActivatedRoute = inject(ActivatedRoute);

  validateSharedAccount(): Observable<any> {
    return this.route.queryParams.pipe(
      switchMap((params) =>
        this.http
          .post<any>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${params.user_id}/verification`, {
            token: params.token
          })
          .pipe(
            map((_) => null),
            catchError((error) => of(error))
          )
      )
    );
  }
}
