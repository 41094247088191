import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@iot-platform/auth';
import { DataGuardComponent } from '@iot-platform/feature/data-guard';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DetailPopupCardsModule, IotPlatformUiModule, UiModule } from '@iot-platform/iot-platform-ui';
import { InfoDisplayPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { TableEngineModule } from '@iot-platform/table-engine';
import { BreadcrumbModule } from '../../components/breadcrumb/breadcrumb.module';
import { NavigationApi } from '../../containers/+state/navigation.api';
import { InfoDisplayComponent } from '../../containers/info-display/info-display.component';
import { dataGuardDeviceProvider } from '../../containers/info-display/settings/data-guard-i4b.provider';
import { DeviceOverviewComponent } from '../devices/containers/device-overview/device-overview.component';
import { StockCardComponent } from './components/stock-card/stock-card.component';
import { StockManagementShellComponent } from './containers/stock-management-shell/stock-management-shell.component';

const stockRoutes: Routes = [
  {
    path: '',
    component: InfoDisplayComponent,
    children: [
      {
        path: '',
        component: StockManagementShellComponent,
        children: []
      },
      {
        path: 'device/:deviceId',
        component: DeviceOverviewComponent,
        canActivate: [AuthGuard],
        providers: [dataGuardDeviceProvider]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(stockRoutes),
    IotPlatformUiModule,
    UiModule,
    BreadcrumbModule,
    SharedModule,
    DetailPopupCardsModule,
    TableEngineModule,
    GridEngineModule,
    InfoDisplayPipe,
    NumberFormatPipe,
    DataGuardComponent
  ],
  exports: [StockManagementShellComponent],
  declarations: [StockManagementShellComponent, StockCardComponent],
  providers: [NavigationApi]
})
export class StockManagementModule {}
