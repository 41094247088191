import { inject } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { AssetEvent, Log } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { AssetEventsService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AssetEventsCommentsActions } from '../actions';

const loadAssetEventComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.loadComments),
      concatMap(({ assetEvent }) =>
        assetEventsService.loadComments(assetEvent).pipe(
          map((comments: Log[]) => AssetEventsCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(AssetEventsCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.addComment),
      concatMap(({ assetEvent, comment }) =>
        assetEventsService.addComment(assetEvent.id, comment).pipe(
          map((addedComment: Log) => AssetEventsCommentsActions.addCommentSuccess({ comment: addedComment })),
          catchError((error) => of(AssetEventsCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.editComment),
      concatMap(({ assetEventId, comment }) =>
        assetEventsService.editComment(assetEventId, comment).pipe(
          map((editedComment: Log) => AssetEventsCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(AssetEventsCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);
//
const addOrEditCommentSuccessThenRefreshEventInAllGrids$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.addCommentSuccess, AssetEventsCommentsActions.editCommentSuccess),
      concatMap(({ comment }) =>
        assetEventsService.getAssetEventById(comment.eventIdentifier).pipe(
          map((updatedItem: AssetEvent) =>
            GridsDbActions.updateItemInAllGridsData({
              updatedItem,
              concept: 'asset-events'
            })
          ),
          catchError((error) => of(AssetEventsCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.deleteComment),
      concatMap(({ assetEvent, commentId }) =>
        assetEventsService.deleteComment(assetEvent.id, commentId).pipe(
          map((deletedCommentId: string) => AssetEventsCommentsActions.deleteCommentSuccess({ commentId: deletedCommentId })),
          catchError((error) => of(AssetEventsCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteCommentSuccessThenRefreshEventInAllGrids$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService), store = inject(Store)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.deleteCommentSuccess),
      withLatestFrom(store.select(fromGrids.getSelectedItemInSelectedGrid)),
      concatMap(([action, event]) =>
        assetEventsService.getAssetEventById(event.id).pipe(
          map((updatedItem: AssetEvent) =>
            GridsDbActions.updateItemInAllGridsData({
              updatedItem,
              concept: 'asset-events'
            })
          ),
          catchError((error) => of(AssetEventsCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetEventsCommentsActions.loadComments,
        AssetEventsCommentsActions.addComment,
        AssetEventsCommentsActions.editComment,
        AssetEventsCommentsActions.deleteComment
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetEventsCommentsActions.loadCommentsSuccess,
        AssetEventsCommentsActions.loadCommentsFailure,
        AssetEventsCommentsActions.addCommentSuccess,
        AssetEventsCommentsActions.addCommentFailure,
        AssetEventsCommentsActions.editCommentSuccess,
        AssetEventsCommentsActions.editCommentFailure,
        AssetEventsCommentsActions.deleteCommentSuccess,
        AssetEventsCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.addCommentSuccess, AssetEventsCommentsActions.editCommentSuccess, AssetEventsCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetEventsCommentsActions.loadCommentsFailure,
        AssetEventsCommentsActions.addCommentFailure,
        AssetEventsCommentsActions.editCommentFailure,
        AssetEventsCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const AssetEventsCommentsEffects = {
  loadAssetEventComments$,
  addComment$,
  editComment$,
  deleteComment$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$,
  addOrEditCommentSuccessThenRefreshEventInAllGrids$,
  deleteCommentSuccessThenRefreshEventInAllGrids$
};
