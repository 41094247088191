import { Injectable } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { PlatformResponse, TagCategory } from '@iot-platform/models/common';

import { Asset, AssetVariable, Device, DeviceEvent, DeviceVariable, I4BBulkOperationApiResponseStatuses, Site } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { DeviceEventsService } from '@iot-platform/shared/services';
import { UserPreferencesService } from '@iot-platform/users';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DeviceEventsCommentsActions, DeviceEventsDbActions, DeviceEventsUiActions } from '../actions';

@Injectable()
export class DeviceEventsEffects {
  loadDeviceEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadDeviceEvents),
      switchMap((action) =>
        this.deviceEventsService.getDeviceEvents(action.request).pipe(
          map((response: PlatformResponse) => DeviceEventsDbActions.loadDeviceEventsSuccess({ response })),
          catchError((error) => of(DeviceEventsDbActions.loadDeviceEventsFailure({ error })))
        )
      )
    )
  );

  loadSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadSiteById),
      switchMap((action) =>
        this.deviceEventsService.getSiteById(action.siteId).pipe(
          map((site: Site) => DeviceEventsDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(DeviceEventsDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadAssetById),
      switchMap((action) =>
        this.deviceEventsService.getAssetById(action.assetId).pipe(
          map((asset: Asset) => DeviceEventsDbActions.loadAssetByIdSuccess({ asset })),
          catchError((error) => of(DeviceEventsDbActions.loadAssetByIdFailure({ error })))
        )
      )
    )
  );

  loadAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadAssetVariableById),
      switchMap((action) =>
        this.deviceEventsService.getAssetVariableById(action.assetVariableId).pipe(
          map((assetVariable: AssetVariable) => DeviceEventsDbActions.loadAssetVariableByIdSuccess({ assetVariable })),
          catchError((error) => of(DeviceEventsDbActions.loadAssetVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadDeviceById),
      switchMap((action) =>
        this.deviceEventsService.getDeviceById(action.deviceId).pipe(
          map((device: Device) => DeviceEventsDbActions.loadDeviceByIdSuccess({ device })),
          catchError((error) => of(DeviceEventsDbActions.loadDeviceByIdFailure({ error })))
        )
      )
    )
  );

  loadDeviceVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadDeviceVariableById),
      switchMap((action) =>
        this.deviceEventsService.getDeviceVariableById(action.deviceVariableId).pipe(
          map((deviceVariable: DeviceVariable) => DeviceEventsDbActions.loadDeviceVariableByIdSuccess({ deviceVariable })),
          catchError((error) => of(DeviceEventsDbActions.loadDeviceVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadTagsByDeviceEventId),
      switchMap((action) =>
        this.deviceEventsService.getTagsByDeviceEventId(action.deviceEventId).pipe(
          map((tags: TagCategory[]) => DeviceEventsDbActions.loadTagsByDeviceEventIdSuccess({ tags })),
          catchError((error) => of(DeviceEventsDbActions.loadTagsByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.updateStatusByDeviceEventId),
      mergeMap((action) =>
        this.deviceEventsService.putStatus(action.status).pipe(
          switchMap((deviceEvent: DeviceEvent) => [
            DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess({ deviceEvent }),
            GridsDbActions.updateItemInAllGridsData({ updatedItem: deviceEvent, concept: 'device-events' })
          ]),
          catchError((error) => of(DeviceEventsDbActions.updateStatusByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.bulkUpdateStatusByDeviceEventIds),
      concatMap((action) =>
        this.deviceEventsService.bulkUpdateStatusByEventType('device-events', action.deviceEventIds, action.status).pipe(
          map((response) => DeviceEventsDbActions.bulkUpdateStatusByDeviceEventIdsSuccess({ response })),
          catchError((error) => of(DeviceEventsDbActions.updateStatusByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatusSuccessThenReloadGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsDbActions.bulkUpdateStatusByDeviceEventIdsSuccess),
      concatLatestFrom(() => [this.store.select(fromGrids.getSelectedGrid)]),
      map(([_, grid]) =>
        GridsDbActions.loadGridData({
          request: {
            filters: get(grid, ['gridOptions', 'filters'], []),
            limit: get(grid, ['data', 'response', 'pagination', 'limit'], 10),
            concept: get(grid, ['masterview']).toLowerCase(),
            page: get(grid, ['data', 'response', 'pagination', 'currentPage'], 0),
            variables: get(grid, ['gridOptions', 'variableNames'], []),
            tags: get(grid, ['gridOptions', 'tagIds'], []),
            endPoint: get(grid, ['gridOptions', 'endPoint'])
          }
        })
      )
    )
  );

  displaySuccessAfterBulkUpdateStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsDbActions.bulkUpdateStatusByDeviceEventIdsSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  loadLogsAfterUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess),
      map((action) => DeviceEventsCommentsActions.loadComments({ deviceEvent: action.deviceEvent }))
    )
  );

  loadMVDeviceEventsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => DeviceEventsDbActions.loadMVSettingsSuccess({ settings })),
          catchError((error) => of(DeviceEventsDbActions.loadMVSettingsFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.saveTableState),
      switchMap((action) =>
        this.deviceEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: DeviceEvent; checked: DeviceEvent[] }) =>
            DeviceEventsDbActions.saveTableStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(DeviceEventsDbActions.saveTableStateFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess, DeviceEventsDbActions.saveMVSettingsSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsDbActions.saveTableStateFailure,
          DeviceEventsDbActions.loadDeviceEventsFailure,
          DeviceEventsDbActions.updateStatusByDeviceEventIdFailure,
          DeviceEventsDbActions.loadMVSettingsFailure,
          DeviceEventsDbActions.saveMVSettingsFailure,
          DeviceEventsDbActions.bulkUpdateStatusByDeviceEventIdsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsUiActions.loadDeviceEvents,
          DeviceEventsUiActions.updateStatusByDeviceEventId,
          DeviceEventsUiActions.saveMVSettings,
          DeviceEventsUiActions.loadMVSettings,
          DeviceEventsUiActions.bulkUpdateStatusByDeviceEventIds
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsDbActions.loadDeviceEventsSuccess,
          DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess,
          DeviceEventsDbActions.loadDeviceEventsFailure,
          DeviceEventsDbActions.updateStatusByDeviceEventIdFailure,
          DeviceEventsDbActions.saveMVSettingsSuccess,
          DeviceEventsDbActions.saveMVSettingsFailure,
          DeviceEventsDbActions.loadMVSettingsSuccess,
          DeviceEventsDbActions.loadMVSettingsFailure,
          DeviceEventsDbActions.bulkUpdateStatusByDeviceEventIdsSuccess,
          DeviceEventsDbActions.bulkUpdateStatusByDeviceEventIdsFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly deviceEventsService: DeviceEventsService,
    private readonly notificationService: NotificationService,
    private readonly userPrefService: UserPreferencesService,
    private readonly store: Store
  ) {}
}
