import { Injectable } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetEvent, AssetVariable, Device, DeviceVariable, I4BBulkOperationApiResponseStatuses, Site } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { AssetEventsService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AssetEventsCommentsActions, AssetEventsDbActions, AssetEventsUiActions } from '../actions';

@Injectable()
export class AssetEventsEffects {
  loadAssetEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadAssetEvents),
      switchMap((action) =>
        this.assetEventsService.getAssetEvents(action.request).pipe(
          map((response: PlatformResponse) => AssetEventsDbActions.loadAssetEventsSuccess({ response })),
          catchError((error) => of(AssetEventsDbActions.loadAssetEventsFailure({ error })))
        )
      )
    )
  );

  loadSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadSiteById),
      switchMap((action) =>
        this.assetEventsService.getSiteById(action.siteId).pipe(
          map((site: Site) => AssetEventsDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(AssetEventsDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadAssetById),
      switchMap((action) =>
        this.assetEventsService.getAssetById(action.assetId).pipe(
          map((asset: Asset) => AssetEventsDbActions.loadAssetByIdSuccess({ asset })),
          catchError((error) => of(AssetEventsDbActions.loadAssetByIdFailure({ error })))
        )
      )
    )
  );

  loadAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadAssetVariableById),
      switchMap((action) =>
        this.assetEventsService.getAssetVariableById(action.assetVariableId).pipe(
          map((assetVariable: AssetVariable) => AssetEventsDbActions.loadAssetVariableByIdSuccess({ assetVariable })),
          catchError((error) => of(AssetEventsDbActions.loadAssetVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadDeviceById),
      switchMap((action) =>
        this.assetEventsService.getDeviceById(action.deviceId).pipe(
          map((device: Device) => AssetEventsDbActions.loadDeviceByIdSuccess({ device })),
          catchError((error) => of(AssetEventsDbActions.loadDeviceByIdFailure({ error })))
        )
      )
    )
  );

  loadDeviceVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadDeviceVariableById),
      switchMap((action) =>
        this.assetEventsService.getDeviceVariableById(action.deviceVariableId).pipe(
          map((deviceVariable: DeviceVariable) => AssetEventsDbActions.loadDeviceVariableByIdSuccess({ deviceVariable })),
          catchError((error) => of(AssetEventsDbActions.loadDeviceVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadTagsByAssetEventId),
      switchMap((action) =>
        this.assetEventsService.getTagsByAssetEventId(action.assetEventId).pipe(
          map((tags: TagCategory[]) => AssetEventsDbActions.loadTagsByAssetEventIdSuccess({ tags })),
          catchError((error) => of(AssetEventsDbActions.loadTagsByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.updateStatusByAssetEventId),
      mergeMap((action) =>
        this.assetEventsService.putStatus(action.status).pipe(
          switchMap((assetEvent: AssetEvent) => [
            AssetEventsDbActions.updateStatusByAssetEventIdSuccess({ assetEvent }),
            GridsDbActions.updateItemInAllGridsData({ updatedItem: assetEvent, concept: 'asset-events' })
          ]),
          catchError((error) => of(AssetEventsDbActions.updateStatusByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.bulkUpdateStatusByAssetEventIds),
      concatMap((action) =>
        this.assetEventsService.bulkUpdateStatusByEventType('asset-events', action.assetEventIds, action.status).pipe(
          map((response) => AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsSuccess({ response })),
          catchError((error) => of(AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatusSuccessThenReloadGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsSuccess),
      concatLatestFrom(() => [this.store.select(fromGrids.getSelectedGrid)]),
      map(([_, grid]) =>
        GridsDbActions.loadGridData({
          request: {
            filters: get(grid, ['gridOptions', 'filters'], []),
            limit: get(grid, ['data', 'response', 'pagination', 'limit'], 10),
            concept: get(grid, ['masterview']).toLowerCase(),
            page: get(grid, ['data', 'response', 'pagination', 'currentPage'], 0),
            variables: get(grid, ['gridOptions', 'variableNames'], []),
            tags: get(grid, ['gridOptions', 'tagIds'], []),
            endPoint: get(grid, ['gridOptions', 'endPoint'])
          }
        })
      )
    )
  );

  bulkUpdateStatusByAssetEventIdsFromEventListPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.bulkUpdateStatusByAssetEventIdsFromEventListPopup),
      concatMap((action) =>
        this.assetEventsService.bulkUpdateStatusByEventType('asset-events', action.assetEventIds, action.status).pipe(
          map((response) => AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsFromEventListPopupSuccess({ response })),
          catchError((error) => of(AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsFromEventListPopupFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkUpdateStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsSuccess, AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsFromEventListPopupSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  loadLogsAfterUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsDbActions.updateStatusByAssetEventIdSuccess),
      map((action) => AssetEventsCommentsActions.loadComments({ assetEvent: action.assetEvent }))
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.saveTableState),
      switchMap((action) =>
        this.assetEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: AssetEvent; checked: AssetEvent[] }) =>
            AssetEventsDbActions.saveTableStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(AssetEventsDbActions.saveTableStateFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsDbActions.updateStatusByAssetEventIdSuccess, AssetEventsDbActions.saveMVSettingsSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsDbActions.saveTableStateFailure,
          AssetEventsDbActions.loadAssetEventsFailure,
          AssetEventsDbActions.updateStatusByAssetEventIdFailure,
          AssetEventsDbActions.loadMVSettingsFailure,
          AssetEventsDbActions.saveMVSettingsFailure,
          AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsUiActions.loadAssetEvents,
          AssetEventsUiActions.updateStatusByAssetEventId,
          AssetEventsUiActions.loadMVSettings,
          AssetEventsUiActions.saveMVSettings,
          AssetEventsUiActions.bulkUpdateStatusByAssetEventIds
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsDbActions.loadAssetEventsSuccess,
          AssetEventsDbActions.updateStatusByAssetEventIdSuccess,
          AssetEventsDbActions.loadAssetEventsFailure,
          AssetEventsDbActions.updateStatusByAssetEventIdFailure,
          AssetEventsDbActions.saveMVSettingsSuccess,
          AssetEventsDbActions.saveMVSettingsFailure,
          AssetEventsDbActions.loadMVSettingsSuccess,
          AssetEventsDbActions.loadMVSettingsFailure,
          AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsSuccess,
          AssetEventsDbActions.bulkUpdateStatusByAssetEventIdsFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly assetEventsService: AssetEventsService,
    private readonly notificationService: NotificationService,
    private readonly store: Store
  ) {}
}
