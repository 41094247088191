<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.TITLE' | translate: { bPName: data?.businessProfile?.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
    <section class="" fxFlex fxLayout="column">
      <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <p>{{ unselectedUsers().length }} {{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.AVAILABLE_USERS' | translate | uppercase }}</p>
      </mat-toolbar>

      <iot-platform-ui-async-autocomplete
        (search)="onSearchUsers($event)"
        [autocomplete]="false"
        [data]="filteredUnselectedUsers()"
        [debounceTime]="300"
        [displayWrapper]="displayUserWrapper"
        [showSpinner]="usersLoading()"
        class="filter-input"
        displayKey="email"
        fxFlex
        placeholder="ADMIN.DIALOG.ADD_ADMIN.FILTER_USERS"
      />

      <mat-list class="height-300 two-line-list" fxFlex>
        @for (user of filteredUnselectedUsers(); track user.id) {
          <mat-list-item [lines]="2" class="hoverable">
            <h3 matListItemTitle>{{ user.firstname }} {{ user.lastname }}</h3>
            <button (click)="addMemberToProfile(user)" class="regular-round-button" mat-icon-button matListItemMeta>
              <mat-icon>person_add</mat-icon>
            </button>
            <p class="complementary-line" matListItemLine>
              <span> {{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.LAST_ACTIVITY' | translate }}</span>
              <span>{{ user.lastActivity | dateFormat }}</span>
            </p>
          </mat-list-item>
        }
      </mat-list>
    </section>

    <section class="" fxFlex fxLayout="column">
      <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
        <p>{{ currentMembers().length }}{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.SELECTED_MEMBERS' | translate | uppercase }}</p>
      </mat-toolbar>

      <mat-form-field class="filter-input" color="accent">
        <mat-label>{{ 'ADMIN.DIALOG.ADD_ADMIN.FILTER_USERS' | translate }}</mat-label>
        <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
        <input (keyup)="filterUsers($event, currentMembers(), true)" matInput type="text" />
      </mat-form-field>

      <mat-list class="height-300 two-line-list" fxFlex>
        @for (user of filteredCurrentMembers(); track user.id) {
          <mat-list-item [lines]="2" class="hoverable">
            <h3 matListItemTitle>{{ user.firstname }} {{ user.lastname }}</h3>
            <button (click)="removeMemberFromProfile(user)" class="regular-round-button" mat-icon-button matListItemMeta>
              <mat-icon>delete</mat-icon>
            </button>
            <p class="complementary-line" matListItemLine>
              <span>{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.LAST_ACTIVITY' | translate }}</span>
              <span>{{ user.lastActivity | dateFormat }}</span>
            </p>
          </mat-list-item>
        }
      </mat-list>
    </section>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" color="accent" mat-raised-button>{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
