import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogsPopupComponent } from '@iot-platform/audit-trail';
import { AnalyticsService } from '@iot-platform/core';
import { ManageTagsDialogComponent, PopupComponent } from '@iot-platform/iot-platform-ui';
import { DeviceIccidComponent } from '@iot-platform/iot4bos/ui/device';
import { TagCategory } from '@iot-platform/models/common';
import { CommandType, Device, DeviceStatusName, Site } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EndMaintenanceDialogComponent } from '../../../../components/maintenance-dialogs/end-maintenance-dialog/end-maintenance-dialog.component';
import { StartMaintenanceDialogComponent } from '../../../../components/maintenance-dialogs/start-maintenance-dialog/start-maintenance-dialog.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DevicesFacade } from '../../state/facades/devices.facade';
import { DeviceInfoFormComponent } from '../device-info-form/device-info-form.component';
import { DeviceMoveToFormComponent } from '../device-move-to-form/device-move-to-form.component';

@Component({
  selector: 'iot4bos-ui-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent implements OnInit, OnDestroy {
  @Input() canCreate = false;
  @Input() canDelete = false;
  @Input() canUpdate = false;
  @Input() canRead = false;
  @Input() canReadAuditTrail = false;
  @Input() site!: Site;

  @Output() configureDeviceConnector: EventEmitter<Device> = new EventEmitter<Device>();

  analytic: AnalyticsService = new AnalyticsService('device_info_page');

  device!: Device;
  isDeviceVarLoaded = false;
  DeviceStatusName = DeviceStatusName;

  tags: TagCategory[] = [];
  tagsLoaded$: Observable<boolean> = this.navigationApi.deviceTagsLoaded$;

  isResetDisabled$: Observable<boolean>;

  displayManageTagsButton: WritableSignal<boolean> = signal(false);
  subscription: Subscription[] = [];

  translateService = inject(TranslateService);

  constructor(
    private readonly dialog: MatDialog,
    private readonly devicesFacade: DevicesFacade,
    private readonly navigationApi: NavigationApi
  ) {}

  ngOnInit() {
    this.subscription.push(
      this.navigationApi.selectedDevice$.subscribe((device: Device) => {
        this.device = device;
        this.setDisplayConfigureTagsButton();
      }),
      this.navigationApi.deviceVariablesLoaded$.subscribe((value) => (this.isDeviceVarLoaded = value)),
      this.navigationApi.deviceTags$.subscribe((tags: TagCategory[]) => (this.tags = tags))
    );
  }

  setDisplayConfigureTagsButton(): void {
    this.displayManageTagsButton.set(
      this.canUpdate && this.device?.status?.name !== DeviceStatusName.decommissioned && this.device?.status?.name !== DeviceStatusName.test_mode
    );
  }

  editDevice() {
    this.analytic.log('tab_device_info_actions', 'open_edit_device');
    const dialogRef = this.dialog.open(DeviceInfoFormComponent, {
      width: '990px',
      data: { device: this.device },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((device: Device) => {
      if (device) {
        this.analytic.log('tab_device_info_actions', 'update_device');
        this.devicesFacade.updateDevice(device);
      }
    });
  }

  moveDevice(device: Device): void {
    this.analytic.log('tab_device_info_actions', 'open_move_device');
    const dialogRef = this.dialog.open(DeviceMoveToFormComponent, {
      width: '800px',
      data: { devices: [device] },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((updatedDevices: Device[]) => {
      if (updatedDevices) {
        this.analytic.log('tab_device_info_actions', 'move_device');
        this.devicesFacade.moveDevices(updatedDevices, this.site);
      }
    });
  }

  resetDevice(deviceToReset: Device) {
    this.analytic.log('tab_device_info_actions', 'open_reset_device');
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { type: 'reset', value: deviceToReset.name },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((validation) => {
      if (validation) {
        this.analytic.log('tab_device_info_actions', 'reset_device');
        this.devicesFacade.resetDevice(deviceToReset);
      }
    });
  }

  manageTags() {
    this.analytic.log('tab_device_info_actions', 'open_manage_tags');
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['device'],
          selectedTags: this.tags,
          objectName: this.device.name,
          currentEntityId: this.device.entity.id,
          multiSelection: false,
          editable: false,
          withChildren: false,
          joinable: true,
          withParents: true,
          enforceMandatoryCategories: true
        }
      })
      .afterClosed()
      .subscribe((tags: TagCategory[]) => {
        if (tags) {
          this.analytic.log('tab_device_info_actions', 'manage_tags', `${tags.length} tags`);
          this.devicesFacade.updateDeviceTags(this.device.id, tags);
        }
      });
  }

  configureDevice(device: Device) {
    this.configureDeviceConnector.emit(device);
  }

  onCommandExec(device: Device, command: CommandType) {
    this.analytic.log('tab_device_info_actions', 'send_command', `${command}`);
    this.devicesFacade.sendCommand(device, { command });
  }

  decommissionDevice(): void {
    this.analytic.log('tab_device_info_actions', 'open_decommission_device');
    if (this.canDelete) {
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: 'DEVICES.CARD.DECOMMISSION_CONFIRMATION_MESSAGE' },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((validation) => {
        if (validation) {
          this.analytic.log('tab_device_info_actions', 'decommission_device');
          const deviceToUpdate: Device = {
            ...this.device,
            status: { ...this.device.status, name: DeviceStatusName.decommissioned }
          };
          this.devicesFacade.updateDevice(deviceToUpdate);
        }
      });
    }
  }

  deleteDevice(device: Device) {
    this.analytic.log('tab_device_info_actions', 'open_delete_device');
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { type: 'delete', value: device.name },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((validation) => {
      if (validation) {
        this.analytic.log('tab_device_info_actions', 'delete_device');
        this.devicesFacade.deleteDevice(device);
      }
    });
  }

  openChangeLog(): void {
    this.analytic.log('tab_device_info_actions', 'open_history');
    this.dialog.open(LogsPopupComponent, {
      data: { concept: 'device', elementId: this.device.id, elementName: this.device.name },
      disableClose: true,
      width: '900px'
    });
  }

  openIccid(): void {
    this.analytic.log('tab_device_info_actions', 'open_device_iccid');
    this.dialog.open(DeviceIccidComponent, {
      data: { deviceId: this.device.id },
      disableClose: false,
      width: '900px'
    });
  }

  onActivate(): void {
    this.analytic.log('tab_device_info_actions', 'open_activate_device');

    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: this.translateService.instant('DEVICES.CARD.ACTIVATE_MESSAGE') },
        disableClose: true
      })
      .afterClosed()
      .pipe(filter((confirmation) => confirmation))
      .subscribe((_) => {
        this.analytic.log('tab_device_info_actions', 'activate_device');
        this.devicesFacade.activateDevice(this.device);
      });
  }

  onStartOrEditMaintenance(): void {
    this.analytic.log('tab_device_info_actions', 'open_schedule_maintenance');
    this.subscription.push(
      this.dialog
        .open(StartMaintenanceDialogComponent, {
          width: '600px',
          disableClose: true,
          data: { elementGoingIntoMaintenance: this.device, type: 'DEVICE' }
        })
        .afterClosed()
        .pipe(filter((deviceToUpdate: Device) => !!deviceToUpdate))
        .subscribe((deviceToUpdate: Device) => {
          this.analytic.log('tab_device_info_actions', 'schedule_maintenance');
          this.devicesFacade.updateDevice({
            ...deviceToUpdate,
            status: { ...deviceToUpdate.status, name: DeviceStatusName.maintenance }
          } as Device);
        })
    );
  }

  onEndMaintenance(): void {
    this.analytic.log('tab_device_info_actions', 'open_end_maintenance');
    this.subscription.push(
      this.dialog
        .open(EndMaintenanceDialogComponent, {
          width: '600px',
          disableClose: true,
          data: { elementEndingMaintenance: this.device, type: 'DEVICE' }
        })
        .afterClosed()
        .pipe(filter((deviceToUpdate: Device) => !!deviceToUpdate))
        .subscribe((deviceToUpdate: Device) => {
          this.analytic.log('tab_device_info_actions', 'end_maintenance');
          this.devicesFacade.updateDevice(deviceToUpdate);
        })
    );
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
