import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { AssetEventsDbActions, AssetEventsUiActions } from '../actions';

export const assetEventsUiFeatureKey = 'assetEventsUi';

export interface State {
  error?: any;
  assetEventsLoaded: boolean;
  assetEventsLoading: boolean;
  siteLoading?: boolean;
  siteLoaded?: boolean;
  assetLoaded?: boolean;
  assetLoading?: boolean;
  assetVarLoaded?: boolean;
  assetVarLoading?: boolean;
  deviceLoaded?: boolean;
  deviceLoading?: boolean;
  deviceVarLoaded?: boolean;
  deviceVarLoading?: boolean;
  tagsLoaded?: boolean;
}

export const initialState: State = {
  error: null,
  assetEventsLoaded: false,
  assetEventsLoading: false,
  siteLoading: false,
  siteLoaded: false,
  assetLoaded: false,
  assetLoading: false,
  assetVarLoaded: false,
  assetVarLoading: false,
  deviceLoaded: false,
  deviceLoading: false,
  deviceVarLoaded: false,
  deviceVarLoading: false,
  tagsLoaded: false
};

const assetEventsUiReducer = createReducer(
  initialState,
  on(
    AssetEventsUiActions.loadAssetEvents,
    (state: State): State => ({
      ...state,
      assetEventsLoading: true,
      assetEventsLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.loadAssetEventsSuccess,
    (state: State): State => ({
      ...state,
      assetEventsLoading: false,
      assetEventsLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadAssetEventsFailure,
    (state: State, { error }): State => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true, error })
  ),
  // ****
  on(
    AssetEventsUiActions.loadMVSettings,
    (state: State): State => ({
      ...state,
      assetEventsLoading: true,
      assetEventsLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.loadMVSettingsSuccess,
    (state: State): State => ({
      ...state,
      assetEventsLoading: false,
      assetEventsLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadMVSettingsFailure,
    (state: State, { error }): State => ({
      ...state,
      assetEventsLoading: false,
      assetEventsLoaded: true,
      error
    })
  ),
  // ****
  on(
    AssetEventsUiActions.saveMVSettings,
    (state: State): State => ({
      ...state,
      assetEventsLoading: true,
      assetEventsLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.saveMVSettingsSuccess,
    (state: State): State => ({
      ...state,
      assetEventsLoading: false,
      assetEventsLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.saveMVSettingsFailure,
    (state: State, { error }): State => ({
      ...state,
      assetEventsLoading: false,
      assetEventsLoaded: true,
      error
    })
  ),
  // ****
  on(AssetEventsUiActions.loadSiteById, (state: State): State => ({ ...state, siteLoading: true, siteLoaded: false })),
  on(
    AssetEventsDbActions.loadSiteByIdSuccess,
    (state: State): State => ({
      ...state,
      siteLoading: false,
      siteLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadSiteByIdFailure,
    (state: State, { error }): State => ({
      ...state,
      siteLoading: false,
      siteLoaded: true,
      error
    })
  ),
  // ****
  on(
    AssetEventsUiActions.loadAssetById,
    (state: State): State => ({
      ...state,
      assetLoading: true,
      assetLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.loadAssetByIdSuccess,
    (state: State): State => ({
      ...state,
      assetLoading: false,
      assetLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadAssetByIdFailure,
    (state: State, { error }): State => ({
      ...state,
      assetLoading: false,
      assetLoaded: true,
      error
    })
  ),
  // ****
  on(
    AssetEventsUiActions.loadDeviceById,
    (state: State): State => ({
      ...state,
      deviceLoading: true,
      deviceLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.loadDeviceByIdSuccess,
    (state: State): State => ({
      ...state,
      deviceLoading: false,
      deviceLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadDeviceByIdFailure,
    (state: State, { error }): State => ({
      ...state,
      deviceLoading: false,
      deviceLoaded: true,
      error
    })
  ),
  // ****
  on(
    AssetEventsUiActions.loadDeviceVariableById,
    (state: State): State => ({
      ...state,
      deviceVarLoading: true,
      deviceVarLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.loadDeviceVariableByIdSuccess,
    (state: State): State => ({
      ...state,
      deviceVarLoading: false,
      deviceVarLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadDeviceVariableByIdFailure,
    (state: State, { error }): State => ({ ...state, deviceVarLoading: false, deviceVarLoaded: true, error })
  ),
  // ****
  on(
    AssetEventsUiActions.loadAssetVariableById,
    (state: State): State => ({
      ...state,
      assetVarLoading: true,
      assetVarLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.loadAssetVariableByIdSuccess,
    (state: State): State => ({
      ...state,
      assetVarLoading: false,
      assetVarLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.loadAssetVariableByIdFailure,
    (state: State, { error }): State => ({ ...state, assetVarLoading: false, assetVarLoaded: true, error })
  ),
  // ****
  on(AssetEventsUiActions.loadTagsByAssetEventId, (state: State): State => ({ ...state })),
  on(AssetEventsDbActions.loadTagsByAssetEventIdSuccess, (state: State): State => ({ ...state, tagsLoaded: true })),
  on(
    AssetEventsDbActions.loadTagsByAssetEventIdFailure,
    (state: State, { error }): State => ({
      ...state,
      tagsLoaded: true,
      error
    })
  ),
  // ****
  on(
    AssetEventsUiActions.bulkUpdateStatusByAssetEventIds,
    (state: State): State => ({
      ...state,
      assetEventsLoaded: false
    })
  ),
  on(
    AssetEventsDbActions.updateStatusByAssetEventIdSuccess,
    (state: State): State => ({
      ...state,
      assetEventsLoaded: true
    })
  ),
  on(
    AssetEventsDbActions.updateStatusByAssetEventIdFailure,
    (state: State, { error }): State => ({
      ...state,
      assetEventsLoaded: true,
      error
    })
  ),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (): State => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsUiReducer(state, action);
}

export const getAssetEventsLoaded = (state: State) => state.assetEventsLoaded;
export const getAssetEventsLoading = (state: State) => state.assetEventsLoading;
export const getSiteLoaded = (state: State) => state.siteLoaded;
export const getSiteLoading = (state: State) => state.siteLoading;
export const getAssetLoaded = (state: State) => state.assetLoaded;
export const getAssetLoading = (state: State) => state.assetLoading;
export const getAssetVariableLoaded = (state: State) => state.assetVarLoaded;
export const getAssetVariableLoading = (state: State) => state.assetVarLoading;
export const getDeviceLoaded = (state: State) => state.deviceLoaded;
export const getDeviceLoading = (state: State) => state.deviceLoading;
export const getDeviceVariableLoaded = (state: State) => state.deviceVarLoaded;
export const getDeviceVariableLoading = (state: State) => state.deviceVarLoading;
export const getTagsLoaded = (state: State) => state.tagsLoaded;
