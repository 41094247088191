import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, ProviderToken } from '@angular/core';
import { AbstractAuditTrailService } from '@iot-platform/audit-trail';
import { CustomEncoder } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, Environment, Pagination } from '@iot-platform/models/common';
import { noop, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type HistoryLogParams = CommonApiRequest;

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService extends AbstractAuditTrailService<Partial<HistoryLogParams>> {
  protected readonly httpClient: HttpClient = inject(HttpClient);
  protected readonly environment: Environment = inject('environment' as unknown as ProviderToken<Environment>);

  get baseUrl() {
    return `${this.environment.api.url}${this.environment.api.endpoints.auditTrail}`;
  }

  getAll(options: Partial<HistoryLogParams>): Observable<CommonApiResponse<HistoryLog, Pagination>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('concept', options.additionalOptions.concept);
    params = params.set('conceptId', options.additionalOptions.elementId);
    return this.httpClient
      .get<CommonApiListResponse<HistoryLog>>(this.baseUrl, { params })
      .pipe(map((response) => ApiHelpers.getHttpResponse<HistoryLog>(response)));
  }

  getById(params: { id: string } & Partial<HistoryLogParams>): Observable<HistoryLogDetail> {
    return this.httpClient.get<HistoryLogDetail>(`${this.baseUrl}/${params.id}`);
  }

  exportLogDetails(): void {
    noop();
  }
}
