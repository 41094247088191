import { NgModule } from '@angular/core';
import { provideTagEditorSettings } from '@iot-platform/iot-platform-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TagEditorService } from '../../services/tag-editor.service';
import { AdminOrganizationsComponent } from './containers/admin-organizations.component';
import { OrganizationsService } from './services/organizations.service';
import { AdminOrganizationsAdministratorsEffects } from './state/effects/admin-organizations-administrators.effects';
import { AdminOrganizationsRolesEffects } from './state/effects/admin-organizations-roles.effects';
import { AdminOrganizationsTagsEffects } from './state/effects/admin-organizations-tags.effects';
import { AdminOrganizationsEffects } from './state/effects/admin-organizations.effects';
import * as fromOrganizations from './state/reducers';

@NgModule({
  exports: [AdminOrganizationsComponent],
  imports: [
    StoreModule.forFeature(fromOrganizations.adminOrganizationsFeatureKey, fromOrganizations.reducers),
    EffectsModule.forFeature([
      AdminOrganizationsEffects,
      AdminOrganizationsRolesEffects,
      AdminOrganizationsAdministratorsEffects,
      AdminOrganizationsRolesEffects,
      AdminOrganizationsTagsEffects
    ]),
    AdminOrganizationsComponent
  ],
  providers: [
    provideTagEditorSettings({
      tagEditorService: TagEditorService
    }),
    OrganizationsService
  ]
})
export class AdminOrganizationsModule {}
