import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { DeviceCallLogMessagePopupComponent } from './device-call-log-message-popup/device-call-log-message-popup.component';
import { DeviceCallLogToolbarComponent } from './device-call-log-toolbar/device-call-log-toolbar.component';
import { DeviceCallLogComponent } from './device-call-log.component';

@NgModule({
  imports: [SharedModule, IotPlatformUiModule, GridEngineModule, DeviceCallLogComponent, DeviceCallLogToolbarComponent, DeviceCallLogMessagePopupComponent],
  exports: [DeviceCallLogComponent, DeviceCallLogToolbarComponent]
})
export class DeviceCallLogModule {}
